<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="content-header">
        <div slot="reference">
          <el-button v-if="status == 3" class="vote-btn" @click="handleEnd">
            <img class="result" src="@/assets/images/interactive/result.png" alt="" /> 
            <span class="end">结果查看</span>
          </el-button>
          <el-button v-else class="vote-btn" @click="handleEnd">
            <img src="@/assets/images/interactive/result.png" alt="" />
            <span class="end">结束</span>
          </el-button>
          <el-popover placement="bottom" width="4rem" trigger="click">
            <div class="pop-content">
              <vue-qrcode
                tag="img"
                :value="`${WX_URL}/vote?voteId=${voteId}`"
                class="qrcode"
              />
              <p>微信扫码投票</p>
            </div>
            <el-button class="vote-btn" slot="reference">
              <img class="qrcode" src="@/assets/images/interactive/qrcode.png" alt="" />
              <span class="a">二维码</span>
            </el-button>
          </el-popover>
        </div>
      </div>
      <div class="vote-list">
        <div class="vote-item" v-for="item in voteList" :key="item.id">
          <div class="vote-cover">
            <img
              :src="`${OSS_URL}/${item.relativePath}/${item.filename}`"
              alt=""
            />
          </div>
          <div class="vote-introduction">
            <span class="vote-name" :title="item.name">{{ item.name }}</span>
            <span class="vote-position" :title="item.post">{{
              item.post
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <menu-pop :activeItem="3"></menu-pop>
    <back :pageDepth="3"></back>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
import back from '@/components/back'
import navRouter from '@/components/navRouter'
import { API_MODULE } from '@/request/api'
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { mapGetters, mapMutations } from 'vuex'
import vueQrcode from '@chenfengyuan/vue-qrcode'
export default {
  name: 'Vote',
  components: {
    menuPop,
    navRouter,
    back,
    vueQrcode
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundColor: '#E03838'
      },
      bgRed: {
        backgroundColor: '#E03838'
      },
      bgBlue: {
        backgroundColor: '#2070DB'
      },
      voteId: undefined,
      status: undefined,
      voteList: []
    }
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    getData() {
      this.GET(API_MODULE.GET_VOTE_DATA, {
        id: this.voteId
      })
        .then((res) => {
          this.voteList = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    handleEnd() {
      this.POST(API_MODULE.GET_END_VOTE, { id: this.voteId })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.$router.push({
              path: '/layout/interactive/vote/result',
              query: { id: this.voteId }
            })
            // 保存下一级路径
            const path = `/layout/interactive/vote/result?id=${this.voteId}`
            this.useRouterList.push({
              name: '投票结果',
              path: path
            })
            this.setRouterList(this.useRouterList, path)
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message(err)
        })
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
      this.bg = this.bgRed
    } else {
      this.icon = blueIcon
      this.bg = this.bgBlue
    }
    this.voteId = this.$route.query.id
    this.status = this.$route.query.status
    this.getData()

    this.useRouterList.find((router, i) => {
      if (!router) return
      if (router.path === this.$route.fullPath) {
        this.useRouterList.splice(i + 1)
      }
    })
    this.setRouterList(this.useRouterList, this.$route.fullPath)
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
        this.bg = this.bgRed
      } else {
        this.icon = blueIcon
        this.bg = this.bgBlue
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if(to.name==="WISDOM_INTERACTION"){
    to.meta.keepAlive = true
    }
    next()
  },
}
</script>

<style lang="scss" scoped>
@import "@/themes/_handle.scss";
.pop-content {
  width: 4rem;
  height: 4.66rem;
  @include background_color("background-medium");
  text-align: center;
  color: #ffffff;
  font-size: 0.36rem;
  .qrcode {
    width: 3rem;
    margin-top: 0.56rem;
    margin-bottom: 0.2rem;
  }
}
.box {
  min-height: 13.6rem;
  background-size: cover;
  background-repeat: no-repeat;
  .content {
    padding: 0.6rem;
    .content-header {
      height: 1rem;

      button {
        margin-right: 0.6rem;
      }


      .vote-btn {
        .end{
            margin-top: 0.08rem;
            float:right;
            font-style: 16px;
            margin-right: -0.1rem;
            // margin-left: 1rem;
            }
        .a{
            margin-top: 0.05rem;
            float:right;
            font-style: 16px;
            margin-right: 0.2rem;
            // margin-left: 1rem;
            }
        .result{
          margin-top: -0.15rem;
          float: left;
          height: 0.6rem;
          width: 0.6rem;
        }
        .qrcode{
          margin-top: -0.15rem;
          float: left;
          height: 0.6rem;
          width: 0.6rem;
        // span{
        //     margin-top: 0.05rem;
        //     float:right;
        //     font-style: 16px;
        //     // font-weight: normal;
        //     margin-right: 0.4rem;
            
        // }
        }
        float: right;
        width: 3.2rem;
        height: 1rem;
        background: #cca766;
        opacity: 1;
        border-radius: 0.05rem;
        font-size: 0.31rem;
        color: #ffffff;
        text-align: center;
        border: 1px solid #cca766;
      }

      .vote-btn:active {
        border: 1px solid #fff;
      }
    }

    .vote-list {
      display: flex;
      flex-wrap: wrap;

      .vote-item {
        margin-right: 0.6rem;
        margin-top: 1.26rem;
        position: relative;

        .vote-cover {
          width: 4.08rem;
          height: 4.9rem;
          border-radius: 0.05rem;
          border: 0.1rem solid #ffffff;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .vote-introduction {
          padding: 0 0.4rem;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1.6rem;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 0 0 0.05rem 0.05rem;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .vote-name {
            margin-right: 1%;
            overflow: hidden;
            /*文本不会换行*/
            white-space: nowrap;
            /*当文本溢出包含元素时，以省略号表示超出的文本*/
            text-overflow: ellipsis;
            width: 80%;
            font-size: 0.38rem;
            font-weight: 600;
            color: #ffffff;
            opacity: 1;
            text-align: left;
          }

          .vote-position {
            font-size: 0.28rem;
            text-align: right;
            width: 50%;
            overflow: hidden;
            word-break: break-all;
            display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
            -webkit-line-clamp: 2; /* 控制最多显示几行 */
            -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
            color: #ffffff;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>